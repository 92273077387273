$(document).ready(initPage);

function initPage() {
  /* Mobile menu */
  let slideout = new Slideout({
    panel: document.getElementById("m-panel"),
    menu: document.getElementById("m-menu"),
    padding: 264,
    side: "right"
  });

  document.querySelector(".toggler").addEventListener("click", function() {
    slideout.toggle();
  });
  slideout.on("beforeclose", function() {
    $("html").addClass("slideout-closing");
  });
  slideout.on("close", function() {
    $("html").removeClass("slideout-closing");
  });

  /* Array down button */
  $('.down a').click(function(e) {

    let next = $(this).parents('section').next();
    $('html, body').animate({
        scrollTop: next.offset().top
    }, 500);
    e.preventDefault();
  });

  /* Testimonials Slider */
  if ($(".testimonials-slider").length) {
    $(".testimonials-img-slider").slick({
      slidesToScroll: 1,
      infinite: true,
      slidesToShow: 1,
      arrows: false,
      dots: false,
      fade: true,
      asNavFor: ".testimonials-slider"
    });
    $(".testimonials-slider").slick({
      slidesToScroll: 1,
      infinite: true,
      slidesToShow: 1,
      arrows: true,
      dots: false,
      asNavFor: ".testimonials-img-slider",
      prevArrow: $(".testimonials-slider-prev"),
      nextArrow: $(".testimonials-slider-next")
    });
  }

  /* Speaking Slider */
  if ($(".speech-slider").length) {
    $(".speech-slider").slick({
      slidesToScroll: 1,
      infinite: true,
      slidesToShow: 2,
      arrows: true,
      dots: false,
      prevArrow: $(".speech-slider-prev"),
      nextArrow: $(".speech-slider-next"),
      responsive: [
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 1
          }
        }
      ]
    });
  }

  /* Video */
  $(".btn-video").magnificPopup({
    closeBtnInside: false,
    closeMarkup: '<button type="button" class="mfp-close"><i class="icon icon-close"></i></button>',
    closeOnBgClick: true,
    fixedContentPos: false,
    callbacks: {
      open: function() {
        jQuery('body').addClass('noscroll');
      },
      close: function() {
        jQuery('body').removeClass('noscroll');
      }
    }
  });

  /* Insights Slider */
  if ($(".insights-slider").length) {
    $(".insights-slider").slick({
      slidesToScroll: 1,
      infinite: true,
      fade: true,
      slidesToShow: 1,
      arrows: true,
      dots: false,
      adaptiveHeight: true,
      prevArrow: $(".insights-slider-prev"),
      nextArrow: $(".insights-slider-next"),
      responsive: [
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 1
          }
        }
      ]
    });
  }

  /* Insights Filter */
  $(".insights-filter .filters .item").click(function() {
    $(this)
      .siblings()
      .removeClass("active");
    $(this).toggleClass("active");
  });

  if (window.location.hash == "#posts") {
    $('#posts .filters .item:first').addClass('active');
  }

  /* Post Slider */
  if ($(".post-slider").length) {

    $(".post-slider").slick({
      slidesToScroll: 1,
      infinite: true,
      fade: true,
      slidesToShow: 1,
      arrows: true,
      dots: false,
      adaptiveHeight: false,
      prevArrow: $(".post-slider-prev"),
      nextArrow: $(".post-slider-next"),
      
    });
  }

  /* Photogallery Slider */
  if ($(".photogallery-slider").length) {

    // $(".photogallery-slider").on('init reInit afterChange', function(event, slick) {
    //   $(".photogallery-slider .counter .total").html(slick.slideCount);
    // });

    $(".photogallery-slider").slick({
      slidesToScroll: 1,
      infinite: true,
      arrows: true,
      dots: false,
      adaptiveHeight: false,
      prevArrow: $(".photogallery-slider-prev"),
      nextArrow: $(".photogallery-slider-next"),
      centerMode: true,
      variableWidth: true,
      responsive: [
        {
          breakpoint: 767,
          settings: {
            centerMode: false,
            variableWidth: false,
            fade: true,
          }
        }
      ]
    });
    
    // $(".photogallery-slider").on("beforeChange", function(event, slick, currentSlide, nextSlide) {
    //   $(".photogallery-slider .counter .current").html(nextSlide + 1);
    // });
  }

  /* Programmes Slider */
  if ($(".section-programmes-4 .programmes-slider").length) {
    $(".section-programmes-4 .programmes-slider").slick({
      slidesToScroll: 1,
      infinite: false,
      fade: false,
      slidesToShow: 3,
      variableWidth: true,
      arrows: true,
      dots: false,
      prevArrow: $(".section-programmes-4 .programmes-slider-prev"),
      nextArrow: $(".section-programmes-4 .programmes-slider-next"),
      responsive: [
        {
          breakpoint: 1199,
          settings: {
            variableWidth: false,
            infinite: true
          }
        },
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 1,
            variableWidth: false,
            infinite: true,
            fade: true,
          }
        },
        {
          breakpoint: 575,
          settings: {
            slidesToShow: 1,
            variableWidth: false,
            infinite: true,
            fade: true,
          }
        }
      ]
    });
  }

  /* Team popups */
  $('.bio-popup-link').magnificPopup({
    type:'inline',
    midClick: true,
    //closeBtnInside: true,
    closeMarkup: '<button type="button" class="mfp-close invert"><i class="icon icon-close"></i></button>',
    closeOnBgClick: true,
    fixedContentPos: false,
    callbacks: {
      open: function() {
        $('body').addClass('noscroll');
        $('.mfp-container').addClass('scrollable');

      },
      close: function() {
        $('body').removeClass('noscroll');
        $('.mfp-container').removeClass('scrollable');
      }
    }
  });


  /* Speakers testimonials slider */
  $(".txt-testimonial-slider").slick({
    slidesToScroll: 1,
    infinite: true,
    arrows: true,
    dots: false,
    adaptiveHeight: false,
    prevArrow: $(".txt-testimonial-slider-prev"),
    nextArrow: $(".txt-testimonial-slider-next"),
  });

  /* Show hidden text */
  $('.show-hidden').click(function() {
    $(this).parent().hide();
    $('#' + $(this).data('hidden')).removeClass('hidden');
    
  });

  /* Client Work Slider */
  if ($(".clientwork-testimonials-slider").length) {
    $(".clientwork-testimonials-slider").slick({
      slidesToScroll: 1,
      infinite: true,
      slidesToShow: 1,
      arrows: true,
      dots: false,
      fade: true,
      asNavFor: ".clientwork-clients-slider",
      prevArrow: $(".clientwork-testimonials-slider-prev"),
      nextArrow: $(".clientwork-testimonials-slider-next")
    });
    $(".clientwork-clients-slider").slick({
      slidesToScroll: 1,
      infinite: true,
      arrows: true,
      dots: false,
      asNavFor: ".clientwork-testimonials-slider",
      prevArrow: $(".clientwork-clients-slider-prev"),
      nextArrow: $(".clientwork-clients-slider-next"),
      centerMode: true,
      //variableWidth: true,
      slidesToShow: 7,
      centerPadding: '60px', 
      responsive: [
        {
          breakpoint: 1199,
          settings: {
            slidesToShow: 5,
            infinite: true
          }
        },
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 3,
            infinite: true
          }
        },
        {
          breakpoint: 575,
          settings: {
            slidesToShow: 1,
            infinite: true
          }
        },
      ]     
    });
  }

  /* Book slider */
  if ($(".book-testimonials-slider").length) {
    $(".book-testimonials-slider").slick({
      slidesToScroll: 1,
      infinite: true,
      slidesToShow: 1,
      arrows: true,
      dots: false,
      fade: true,
      adaptiveHeight: true,
      prevArrow: $(".book-testimonials-slider-prev"),
      nextArrow: $(".book-testimonials-slider-next")
    });
  }

  /* Book purchase number */
  $('.num-spinner .btn-spinner-down').click(function() {
    if($('.num-spinner .form-control').get(0).value > 1) {
      $('.num-spinner .form-control').get(0).value--;
    }
  });

  $('.num-spinner .btn-spinner-up').click(function() {
    $('.num-spinner .form-control').get(0).value++;
  });

  /* Book purchase popups */
  let purchaseError = true, purchasePopup = '';

  updatePurchase();

  $('#test-toggle-book-popup').click(function() {
    purchaseError = !purchaseError;
    updatePurchase();
  })

  function updatePurchase() {
    purchasePopup = purchaseError ? '#book-purchase-popup-1' : '#book-purchase-popup-2';
    initBookPurchasePopup();
  }

  function initBookPurchasePopup() {
    $('.btn-book-purchase').magnificPopup({
      items: {
        src: purchasePopup,
        type: 'inline'
      },
      type:'inline',
      midClick: true,
      closeBtnInside: true,
      closeMarkup: '<button type="button" class="mfp-close mfp-close-basic"><i class="icon icon-close"></i></button>',
      closeOnBgClick: true,
      fixedContentPos: false,
      callbacks: {
        open: function() {
          $('body').addClass('noscroll');
          $('.mfp-container').addClass('scrollable');

        },
        close: function() {
          $('body').removeClass('noscroll');
          $('.mfp-container').removeClass('scrollable');
        }
      }
    });
  }
  
  /* Contact */
  if (typeof autosize === "function" && $('*[data-autosize]').length) {
    $('textarea[data-autosize]').each(function(){
        autosize(this);
    });
  }

  $('.custom-checkbox .wpcf7-list-item').click(function() {
    $(this).find('input').each(function () { this.checked = !this.checked; });

    if ($('.custom-checkbox input:checkbox:checked').length > 0) {
      $('.custom-checkbox').addClass('chosen');
    } else {
      $('.custom-checkbox').removeClass('chosen');
    }
  });


  $('.btn-contact-popup').magnificPopup({
    type:'inline',
    midClick: true,
    closeBtnInside: true,
    closeMarkup: '<button type="button" class="mfp-close mfp-close-basic"><i class="icon icon-close"></i></button>',
    closeOnBgClick: true,
    fixedContentPos: false,
    callbacks: {
      open: function() {
        $('body').addClass('noscroll');
        $('.mfp-container').addClass('scrollable');

      },
      close: function() {
        $('body').removeClass('noscroll');
        $('.mfp-container').removeClass('scrollable');
      }
    }
  });

}
